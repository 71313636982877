import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const InsAdministrativeUnit = EgretLoadable({
    loader: () => import("./InsAdministrativeUnit")
});
const ViewComponent = withTranslation()(InsAdministrativeUnit);

const InsAdministrativeUnitRoutes = [
    {
        path: ConstantList.ROOT_PATH + "dashboard/insAdministrativeUnit",
        exact: true,
        component: ViewComponent
    }
];

export default InsAdministrativeUnitRoutes;