import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const SearchplanEnterprise = EgretLoadable({
  loader: () => import("./SearchplanEnterprise")
});

const ViewComponent = withTranslation()(SearchplanEnterprise);

const SearchplanEnterpriseService = [
  {
    path:  ConstantList.ROOT_PATH+"searchplanenterprise",
    exact: true,
    component: ViewComponent
  }
];

export default SearchplanEnterpriseService;
