import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


const RelatedObject = EgretLoadable({
    loader: () => import("./RelatedObject")
  });
const ViewComponent = withTranslation()(RelatedObject);
const RelatedObjectRoutesRoutes = [
  {
    path: ConstantList.ROOT_PATH + "relatedObject",
    exact: true,
    component: ViewComponent,
    isPublic: false
  }
];

export default RelatedObjectRoutesRoutes;