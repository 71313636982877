import { EgretLoadable } from "egret";
import { withTranslation } from 'react-i18next';
import ConstantList from "../../appConfig";
const PlanApprovalAdmin = EgretLoadable({
    loader: () => import("./PlanApprovalAdmin")
});
const ViewComponent = withTranslation()(PlanApprovalAdmin);

const planApprovalAdminRoutes = [
    {
        path: ConstantList.ROOT_PATH + "planApproval-admin",
        exact: true,
        component: ViewComponent
    }
];

export default planApprovalAdminRoutes;