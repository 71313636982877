import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const Planning = EgretLoadable({
  loader: () => import("./Planning")
});

const ViewComponent = withTranslation()(Planning);

const PlanningRoutes = [
  {
    path:  ConstantList.ROOT_PATH+"planning/:type",
    exact: true,
    component: ViewComponent
  }
];

export default PlanningRoutes;
