import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import ConstantList from "../../../app/appConfig.js";
import { connect, MapStateToProps } from 'react-redux';
import { getCurrentUser } from '../../../app/views/page-layouts/UserProfileService';
import { navigations } from "./navigations.js";

class EgretHorizontalNav extends Component {
  state = {
    //  collapsed: true,
    //   isCheck: false,
    //  navigationMore: [],
  };
  componentWillMount(){ }
  


  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      navigationMore: [],
      firsTime: 0,
      firstCheck: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  // componentDidMount(){
  //   this.checkMore();
  // }
  renderLevels = (levels, isParent) => {
    const { t, i18n, classes, navigation, max } = this.props;
    let { isChec } = this.state;
    // navigationMore = [];
    return levels.map((item, key) => {
      // console.log(item)
      if (isParent) {
        let checkRoles = false;
        if (item.roles) {
          item.roles.map((itemRole) => {
            if (this.props.user?.roles) {
              let indexRole = this.props.user.roles.findIndex(itemRoleUser => itemRoleUser.authority === itemRole)
              if (indexRole >= 0) {
                checkRoles = true
              }
            }
          })
        }
        if (checkRoles) {
          if (
            item.path && item.path.length > 0 &&
            !item.path.startsWith(ConstantList.ROOT_PATH)
          ) {
            item.path = ConstantList.ROOT_PATH + item.path;
          }
          if ((item.children && item.children.length > 0)) {
            return (
              <li key={key} className="grandParentLi">
                <a href={item.path}>
                  {item.icon && (
                    <Icon className="item-icon text-middle">{item.icon}</Icon>
                  )}
                  {t(item.name)}
                </a>
                <ul>{this.renderLevels(item.children)}</ul>
              </li>
            );
          } else {
            return (
              <li key={key} className="grandParentLi" onClick={this.handleClick}>
                <NavLink to={item.path} >
                  {item.icon && (
                    <Icon className="item-icon text-middle">{item.icon}</Icon>
                  )}
                  {t(item.name)}
                </NavLink>
              </li>
            );
          }
        }
        else return <></>

      } else {
        if (
          item.path && item.path.length > 0 &&
          !item.path.startsWith(ConstantList.ROOT_PATH)
        ) {
          item.path = ConstantList.ROOT_PATH + item.path;
        }
        if ((item.children && item.children.length > 0)) {
          return (
            <li key={key}>
              <a href={item.path}>
                {item.icon && (
                  <Icon className="item-icon text-middle">{item.icon}</Icon>
                )}
                {t(item.name)}
              </a>
              <ul>{this.renderLevels(item.children)}</ul>
            </li>
          );
        } else {
          return (
            <li key={key} onClick={this.handleClick}>
              <NavLink to={item.path} >
                {item.icon && (
                  <Icon className="item-icon text-middle">{item.icon}</Icon>
                )}
                {t(item.name)}
              </NavLink>
            </li>
          );
        }
      }

      // if (
      //   item.path && item.path.length > 0 &&
      //   !item.path.startsWith(ConstantList.ROOT_PATH)
      // ) {
      //   item.path = ConstantList.ROOT_PATH + item.path;
      // }
      // if ((item.children && item.children.length > 0)) {
      //   return (
      //     <li key={key}>
      //       <a href={item.path}>
      //         {item.icon && (
      //           <Icon className="item-icon text-middle">{item.icon}</Icon>
      //         )}
      //         {t(item.name)}
      //       </a>
      //       <ul>{this.renderLevels(item.children)}</ul>
      //     </li>
      //   );
      // } else    {
      //   return (
      //     <li key={key} onClick={this.handleClick}>
      //       <NavLink to={item.path} >
      //         {item.icon && (
      //           <Icon className="item-icon text-middle">{item.icon}</Icon>
      //         )}
      //         {t(item.name)}
      //       </NavLink>
      //     </li>
      //   );
      // }
    });
  };
  handleClick = (event) => {
    const grandParent = event.target.closest('.navbar')
    const arrGrand = grandParent.querySelectorAll('.grandParentLi')
    arrGrand.forEach((item)=>{
      if(item.classList.contains('primary')){
        item.classList.remove('primary')
      }
    })
    const parent = event.target.closest('.grandParentLi')
    parent.classList.add('primary')
    
    // debugger
    // let a = document.querySelectorAll(".grandParentLi")
    // if (event.target.innerText === "Kế hoạch trùng lặp") {
    //   let c = "Phê duyệt";
    //   a.forEach(item => console.log('hhh', item))
    //   console.log('yyy', a)
    //   a.classList.add("bbb")
    // }
    this.setState({ collapsed: !this.state.collapsed, boldColor: true });
  };
  checkMore = (navigations) => {
    let checkRoles = false;
    let { navigationMore } = this.state;
    navigations.map((item, index) => {
      if (index >= this.props.max) {
        if (item.roles) {
          item.roles.map((itemRole) => {
            if (this.props.user?.roles) {
              let indexRole = this.props.user.roles.findIndex(itemRoleUser => itemRoleUser.authority === itemRole)
              if (indexRole >= 0) {
                checkRoles = true
              }
            }
          })
        }
      }
      if (checkRoles == true) {
        navigationMore.push(item);
        this.setState({ navigationMore: navigationMore })
      }
    })
    this.setState({ firstCheck: false });
  }

  render() {
    let max = this.props.max;
    let { navigationMore, firstCheck } = this.state;
    let navigation = navigations;
    navigation.map((item, index) => {
      let checkRoles = false;
      if (item.roles) {

        item.roles.map((itemRole) => {
          if (this.props.user?.roles) {
            let indexRole = this.props.user.roles.findIndex(itemRoleUser => itemRoleUser.authority === itemRole)
            if (indexRole >= 0) {
              checkRoles = true
            }
          }
        })
      }
      if (!checkRoles) {
        navigation.splice(index, 1)
      }
    })

    if (max && navigation.length > max) {
      if (firstCheck) {
        this.checkMore(navigation)
      }
      let childItem = {
        name: "More",
        icon: "more_vert",
        // children: navigation.slice(max, navigation.length),
        children: navigationMore,
        roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_REPORT"],
      };
      navigation = navigation.slice(0, max);
      navigation.push(childItem);
    }
    if (navigation < 7) {
      navigation = navigationMore;

    }
    return (
      <div className="horizontal-nav">
        <ul className="menu">{this.renderLevels(navigation, true)}</ul>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  activeNavbar: state.layout.activeNavbar,
  text: state.layout.text
});
export default connect(
  mapStateToProps)(EgretHorizontalNav);
