import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { withTranslation } from 'react-i18next';


const Administration = EgretLoadable({
  loader: () => import("./Administration")
});
const ViewComponent = withTranslation()(Administration);
const AdministrationRoutes = [
  {
    path: ConstantList.ROOT_PATH + "administration",
    exact: true,
    component: ViewComponent,

  }
];

export default AdministrationRoutes;