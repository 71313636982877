import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { withTranslation } from 'react-i18next';


const Central = EgretLoadable({
    loader: () => import("./Central")
  });
const ViewComponent = withTranslation()(Central);
const CentralRoutes = [
  {
    path: ConstantList.ROOT_PATH + "central",
    exact: true,
    component: ViewComponent,
   
  }
];

export default CentralRoutes;