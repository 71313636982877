import { EgretLoadable } from "egret";
import ConstantList from "../../../appConfig";
import { withTranslation } from 'react-i18next';
const HandlingAfterInspectionEnterprise = EgretLoadable({
  loader: () => import("./HandlingAfterInspectionEnterprise")
});

const ViewComponent = withTranslation()(HandlingAfterInspectionEnterprise);

const HandlingAfterInspectionEnterpriseRouters = [
  {
    path:  ConstantList.ROOT_PATH+"handlingAfterInspectionEnterprise/:monitorStatus",
    exact: true,
    component: ViewComponent
  }
];

export default HandlingAfterInspectionEnterpriseRouters;
