import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const SyntheticAdmin = EgretLoadable({
  loader: () => import("./SyntheticAdmin")
});
const ViewComponent = withTranslation()(SyntheticAdmin);

const syntheticAdminRoutes = [
  {
    path: ConstantList.ROOT_PATH + "synthetic-admin",
    exact: true,
    component: ViewComponent
  },
];

export default syntheticAdminRoutes;