import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


const Enterprise = EgretLoadable({
    loader: () => import("./Enterprise")
  });
const ViewComponent = withTranslation()(Enterprise);
const EnterpriseRoutes = [
  {
    path: ConstantList.ROOT_PATH + "enterprise",
    exact: true,
    component: ViewComponent,
   
  }
];

export default EnterpriseRoutes;