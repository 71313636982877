const APPLICATION_PATH = "/";

module.exports = Object.freeze({
  //ROOT_PATH : "/egret/",
  ROOT_PATH: APPLICATION_PATH,
  ACTIVE_LAYOUT: "layout2", //layout1 = vertical, layout2=horizontal
  // API_ENPOINT: "http://192.168.0.107:8999/inspections",     //local
  // API_ENPOINT: "https://557c-222-252-21-66.ap.ngrok.io/inspections", //local

  // API_ENPOINT: "http://thanhtrahp.globits.net:8999/inspections",    //online
  // API_ENPOINT: "http://api.oceantech.vn/inspections",//Thanh tra Hải phòng
  // API_ENPOINT: "http://api.oceantech.vn/beta-bg-inspections", //thanh tra tỉnh đắc nông
  API_ENPOINT: "https://thanhtrabacgiang.net/inspections-bg",
  LOGIN_PAGE: APPLICATION_PATH + "session/signin", //Nếu là Spring
  HOME_PAGE: APPLICATION_PATH + "dashboard/analytics", //Nếu là Spring

  //HOME_PAGE:APPLICATION_PATH+"dashboard/learning-management"//Nếu là Keycloak
  //HOME_PAGE:APPLICATION_PATH+"landing3",//Link trang landing khi bắt đầu
  INSPECTIONS_TYPE_ADMINISTRATION: 1, //hành chính
  INSPECTIONS_TYPE_ENTERPRISE: 2, //doanh nghiệp
  INSPECTIONS_TYPE_CENTRAL: 3, //Trung ương
  INSPECTIONS_TYPE_ALL: 4, // All

  ORG_TYPE_INSPECTION_UNIT: 1, //Đơn vị thanh tra
  ORG_TYPE_INSPECTED_UNIT: 2, //Đơn vị bị thanh tra
  ORG_TYPE_ALL: 3, //Tất cả

  ID_USER_APPROVE: 34,
  USERNAME_PHONGNGHIEPVU5: "phongnghiepvu5",
  CODE_PHONGNGHIEPVU5: "25",
  ID_USER_PHONGNGHIEPVU5: 44,
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",

  INSPECTED_UNIT_TYPE_IBH: 1, //Đối tượng bị thanh tra là hộ kinh doanh cá thể
  INSPECTED_UNIT_TYPE_ENTERPRISE: 2, //Đối tượng bị thanh tra là doanh nghiệp
  INSPECTED_UNIT_TYPE_ADMINISTRATION: 3, //Đối tượng bị thanh tra là đơn vị hành chính

  INSPECTIONS_STATUS_NOT_APPROVED: -1, //Không duyệt
  INSPECTIONS_STATUS_DRAFT: 1, //Bản nháp
  INSPECTIONS_STATUS_PENDING: 2, // Chờ duỵet
  INSPECTIONS_STATUS_APPROVED: 3, //Đã phê duyệt
  INSPECTIONS_STATUS_PROCESSING: 4, // Đang thực hiện
  INSPECTIONS_STATUS_CONCLUDED: 5, //Đã kết luận
  INSPECTIONS_STATUS_COMPLETE: 6, //Hoàn thành
  INSPECTIONS_STATUS_OPINION_LEADER: 7, //Ý kiến lãnh đạo
  INSPECTIONS_STATUS_CONFIRM: 8, // chờ phê duyệt lại
  INSPECTIONS_DISPLAY_STATUS_APPROVED_TO_COMPLETE: 4,

  INSPECTION_MONITOR_STATUS_FOLLOW: 1,
  INSPECTION_MONITOR_STATUS_URGE: 2,
  INSPECTION_MONITOR_STATUS_CHECK: 3,
  INSPECTION_MONITOR_STATUS_REQUEST: 4,

  INSPECTION_IS_OLD_TRUE: true,
  INSPECTION_IS_OLD_FALSE: false,

  INSPECTION_MONITOR_STATUS_TYPE: [
    { id: null, name: "Thao tác", classLable: "inspection checkBox conclude" }, //Mới kết luận
    { id: 1, name: "Theo dõi", classLable: "inspection checkBox follow" }, //Follow
    { id: 2, name: "Đôn đốc", classLable: "inspection checkBox warning" }, //Urge
    { id: 3, name: "Kiểm tra", classLable: "inspection checkBox info" }, //Check
    { id: 4, name: "Kiến nghị", classLable: "inspection checkBox other" }, //Request
  ],
  MONITOR_STATUS_LIST: {
    follow: 1,
    urge: 2,
    check: 3,
    suggestion: 4
  },

  ADMINISTRATION_SUGGEST_STATUS: [
    { id: 3, name: "Chưa thực hiện" },
    { id: 4, name: "Đang thực hiện" },
    {id: 5, name: "Hoàn thành"}
  ],

  RESULT_LIST: [
    { id: 1, name: "Tạo mới" },
    { id: 2, name: "Theo dõi" },
    { id: 3, name: "Đôn đốc" },
    { id: 4, name: "Kiểm tra" },
    { id: 0, name: "Kiến nghị" },
  ],
  LIST_STATUS_OF_INS: [
    { id: 5, name: "Đang xử lý" },
    { id: 6, name: "Hoàn thành" },
  ],
  // ADMINISTRATION_SUGGEST_STATUS_LIST: {
  //   notExecute: 3,
  //   execute: 4,
  //   finish: 5
  // },
  OTHER_TREATMENT: {
    money: 1,
    area: 2,
  },
  PROCESS_TYPE: {
    openTrackRecord: 1,
    trackingReport: 2,
    transferToUrge: 3,
    urgingReport: 4,
    transferToCheck: 5,
    transferToRequest: 6
  },
  INSPECTIONS_STATUS: [
    {
      id: -1,
      name: "Từ chối",
      nameInProgress: "Từ chối",
      classLable: "inspection label other",
    }, //Draft
    {
      id: 1,
      name: "Bản nháp",
      nameInProgress: "Bản nháp",
      classLable: "inspection label other",
    }, //Draft
    {
      id: 2,
      name: "Chờ phê duyệt",
      nameInProgress: "Chờ phê duyệt",
      classLable: "inspection label other",
    }, //Pending
    {
      id: 3,
      name: "Đã phê duyệt",
      nameInProgress: "Chưa tiến hành",
      classLable: "inspection label info",
    }, //Approved
    {
      id: 4,
      name: "Đang thực hiện",
      nameInProgress: "Đang tiến hành",
      classLable: "inspection label info",
    }, //Processing
    {
      id: 5,
      name: "Đã kết luận",
      nameInProgress: "Đã kết luận",
      classLable: "inspection label success",
    }, //Concluded
    {
      id: 6,
      name: "Hoàn thành",
      nameInProgress: "Hoàn thành",
      classLable: "inspection label success",
    }, //Complete
    {
      id: 7,
      name: "Yêu cầu sửa lại",
      nameInProgress: "Yêu cầu sửa lại",
      classLable: "inspection label danger",
    }, //Edit
    {
      id: 8,
      name: "Chờ phê duyệt lại",
      nameInProgress: "Chờ phê duyệt lại",
      classLable: "inspection label warning",
    }, //Edit
    {
      id: 9,
      name: "Trạng thái trùng",
      nameInProgress: "Trạng thái trùng",
      classLable: "inspection label danger",
    }, //Edit
  ],
  INSPECTIONS_SUB_TYPE: [
    { id: 1, name: "Thanh tra", classLable: "" }, //Inspections
    { id: 2, name: "Kiểm tra", classLable: "" }, //Test
  ],
  GET_INSPECTIONS_SUB_TYPE: {
    Inspections: 1, //Thanh tra
    Test: 2, //Kiểm tra
  },
  GET_DOCUMENT_BY_MONITOR_STATUS_TYPE: {
    WrittenRequestForPerformance: 1, //Văn bản yêu cầu thực hiện
    ReportTrackingResults: 2, //Báo cáo kết quả theo dõi
    LetterOfUrging: 3, //Văn bản đôn đốc
    UrgeResultReport: 5, //Báo cáo kết quả đôn đốc
    DocumentCheckDecision: 6, //Văn bản quyết định kiểm tra
    DocumentCheckNotice: 7, //Văn bản thông báo kiểm tra
    DocumentCheckRecord: 8, //Văn bản biên bản kiểm tra
    DocumentCheckResult: 9, //Văn bản báo cáo kết quả kiểm tra
    RecommendationsAfterIns: 10 //Kiến nghị sau kiểm tra
  },
  INSPECTIONS_STATUS_1: [
    {
      id: 3,
      name: "Đã phê duyệt",
      nameInProgress: "Chưa bắt đầu",
      classLable: "inspection label info",
    },
    {
      id: 4,
      name: "Đang thực hiện",
      nameInProgress: "Đang thực hiện",
      classLable: "inspection label warning",
    }, //Processing
    {
      id: 5,
      name: "Đã kết luận",
      nameInProgress: "Đã kết luận",
      classLable: "inspection label success",
    }, //Concluded
    {
      id: 6,
      name: "Hoàn thành",
      nameInProgress: "Hoàn thành",
      classLable: "inspection label success",
    }, //Complete
    {
      id: 7,
      name: "Chỉnh sửa lại",
      nameInProgress: "Chỉnh sửa lại",
      classLable: "inspection label warning",
    }, //Edit
    {
      id: 8,
      name: "Chờ phê duyệt lại",
      nameInProgress: "Chờ phê duyệt lại",
      classLable: "inspection label warning",
    }, //confirm
    {
      id: 9,
      name: "Trạng thái trùng",
      nameInProgress: "Trạng thái trùng",
      classLable: "inspection label danger",
    },
  ],
  FOLLOWUPRESOLUTIONTYPE_COMPLAIN: 1,
  FOLLOWUPRESOLUTIONTYPE_DENOUNCE: 2,
  FOLLOWUPRESOLUTIONTYPE_REQUEST: 3,

  GET_INSPECTIONS_TYPE_DIFF_ENTERPRISE: [
    { id: 1, name: "Hành chính" },
    { id: 3, name: "Trung ương" },
  ],

  INS_ADMINTRATIVE_UNIT_Province: 1,
  INS_ADMINTRATIVE_UNIT_District: 2,
  INS_ADMINTRATIVE_UNIT_Ward: 3,

  GET_INSPECTED_RESULT_LAND: 1,
  GET_INSPECTED_RESULT_CURRENCY: 2,

  // danh sách kiến nghị khác
  // OTHER_RECOMENDATION_TYPE_MONEY: 1, // Về tiền bạc
  // OTHER_RECOMENDATION_TYPE_ADMINISTRATIVELY: 2, // Về hành chính
  // OTHER_RECOMENDATION_TYPE_AREA: 3, // Về diện tích
  // OTHER_RECOMENDATION_TYPE_INVESTIGATIVE_AGENCY: 4, // Về cơ quan điều tra

  DISPLAYSTATUS_BEFORE_PROCESSING: 1,
  DISPLAYSTATUS_AFTER_APPROVED: 2,
  DISPLAYSTATUS_BEFORE_PROCESSING_WITHOUT_APPROVED: 3,
  DISPLAYSTATUS_AFTER_APPROVED_WITHOUT_REAPPROVED: 4,

  addListOption: {
    createDate: null,
    createdBy: null,
    description: "Test",
    id: "a92f52362e1a",
    modifiedBy: null,
    modifyDate: null,
    name: "Khác",
    type: "PLANT_CONTACT_ENTERPRISE",
    voided: false
  },

  responseStatus: {
    successCode: 200,
  },

  listRole: {
    ROLE_APPROVAL_USER_UPDATE: 16,
    ROLE_ADMIN: 1
  },

  invalid: {
    date: "Invalid Date",
  },

  PLAN_APPROVAL_STATUS: {
    REFUSE: -1, //"Từ chối"
    DRAFT: 1, //"Bản nháp"
    PENDING: 2, //"Chờ Duyệt"
    APPROVED: 3, //"Đã phê duyệt"
    PROCESSING: 4, //"Đang thực hiện"
    CONCLUDED: 5, //"Đã kết luận"
    COMPLETE: 6, //"Hoàn thành"
    EDIT_REQUEST: 7, //"Yêu cầu chỉnh sửa"
    WAIT_APPROVE: 8, //"Chờ đợi duyệt"
    MEGRED: 9, //"Đẫ được merge"
  },

  listRoleName: {
    ROLE_APPROVAL_USER_UPDATE: "ROLE_APPROVAL_USER_UPDATE",
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    ROLE_REPORT: "ROLE_REPORT",
    ROLE_APPROVAL_MANAGEMENT: "ROLE_APPROVAL_MANAGEMENT",
    ROLE_INSPECTION_HANDLING: "ROLE_INSPECTION_HANDLING",
    ROLE_CONDUCT_INSPECTION: "ROLE_CONDUCT_INSPECTION",
    ROLE_SUMMARIZING_RESULT: "ROLE_SUMMARIZING_RESULT",
    ROLE_UNIT_MANAGEMENT: "ROLE_UNIT_MANAGEMENT",
    ROLE_RESOLVE_COMPLAINTS: "ROLE_RESOLVE_COMPLAINTS",
    ROLE_PLAN_MANAGEMENT: "ROLE_PLAN_MANAGEMENT",
    ROLE_APPROVAL_MANAGEMENT_UPDATE: "ROLE_APPROVAL_MANAGEMENT_UPDATE"
  },

  KEYBOARD: {
    ENTER: { name: "Enter", code: 13 }
  },

  DOCUMENT_TYPE: {
    REQUESTING_IMPLEMENTATION: 1, //Văn bản yêu cầu thực hiện
    TRACKING_REPORT: 2, //Báo cáo theo dõi
    URGING_WORK: 3, //Văn bản đôn đốc
    URGING_NOTICE: 4, //Thông báo lịch làm việc với đối tượng đôn đốc
    URGING_REPORT: 5, //Báo cáo đôn đốc
    DECIDED_TO_CHECK: 6, //Quyết định kiểm tra
    INSPECTION_NOTICE: 7, //Thông báo kiểm tra
    INSPECTION_RECORDS: 8, //Biên bản kiểm tra
    INSPECTION_REPORT: 9, //Báo cáo kiểm tra
    RECOMEND_AFTER_INSPECTION: 10,//Kiến nghị sau kiểm tra
  },

  TABS_INDEX: {
    CHECK_NOTICE: 0,
    CHECK_RECORD: 1,
    CHECK_RESULT: 2
  },

  TICK_NUMBERS: {
    AGENCY_NAME: "1",
    MONITORING_AGENCY: "2",
    NAME_INSPECTION: "3",
    SUBJECTS_OF_INSPECTION: "4",
    INSPECTION_UNIT: "5",
    RECOMMENDATIONS: "6",
    PERFORM: "6.1",
    UNFULFILLED: "6.2",
    FOLLOW_UP_CHIEF: "7",
    HELPER: "8",
    MANAGER_PHONE: "9",
    AUDITOR: "10",
    INSPECTION_DUTIES: "11"
  },

  TYPE_OPTIONS_PLANCONTENT: 1
});