import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


const ReportAdministrationPlan = EgretLoadable({
    loader: () => import("./ReportAdministrationPlan")
  });
const ViewComponent = withTranslation()(ReportAdministrationPlan);
const ReportAdministrationPlanRoutes = [
  {
    path: ConstantList.ROOT_PATH + "reportAdministrationPlan",
    exact: true,
    component: ViewComponent,
    isPublic: false
  }
];

export default ReportAdministrationPlanRoutes;