import { EgretLoadable } from "egret";
import ConstantList from "../../../appConfig";
import { withTranslation } from 'react-i18next';
const HandlingAfterInspectionAdmin = EgretLoadable({
  loader: () => import("./HandlingAfterInspectionAdmin")
});

const ViewComponent = withTranslation()(HandlingAfterInspectionAdmin);

const HandlingAfterInspectionAdminRouters = [
  {
    path:  ConstantList.ROOT_PATH+"handlingAfterInspectionAdmin/:type/:monitorStatus",
    exact: true,
    component: ViewComponent
  }
];

export default HandlingAfterInspectionAdminRouters;
