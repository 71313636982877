import ConstantList from '../../../app/appConfig';
export const navigations = [
    {
      name: "Dashboard.dashboard",
      icon: "home",
      path: ConstantList.ROOT_PATH + "dashboard/analytics",
      isVisible: true,
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_REPORT,
        ConstantList.listRoleName.ROLE_APPROVAL_MANAGEMENT,
        ConstantList.listRoleName.ROLE_INSPECTION_HANDLING,
        ConstantList.listRoleName.ROLE_CONDUCT_INSPECTION,
        ConstantList.listRoleName.ROLE_SUMMARIZING_RESULT,
        ConstantList.listRoleName.ROLE_RESOLVE_COMPLAINTS,
        ConstantList.listRoleName.ROLE_PLAN_MANAGEMENT,
        ConstantList.listRoleName.ROLE_UNIT_MANAGEMENT
      ],
    },
    {
      name: "Dashboard.inspectionPlan",
      isVisible: true,
      icon: "feed",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_PLAN_MANAGEMENT
      ],
      children: [
        {
          name: "inspectionPlan.planning_central",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "central",
        },
        {
          name: "inspectionPlan.planning_admin",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "administration",
        },
        {
          name: "inspectionPlan.planning_enterprise",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "enterprise",
        },
      ],
    },
    {
      name: "Dashboard.planApproval",
      isVisible: true,
      icon: "feed",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_APPROVAL_MANAGEMENT,
      ],
      children: [
        {
          name: "Dashboard.administration",
          isVisible: true,
          icon: "keyboard_arrow_right",
          children: [
            {
              name: "planApproval.no_duplicate",
              isVisible: true,
              path: ConstantList.ROOT_PATH + "planApproval-admin",
            },
            {
              name: "planApproval.duplicate",
              isVisible: true,
              path: ConstantList.ROOT_PATH + "planAppro-duplicate-admin",
            },
          ],
        },
        {
          name: "Dashboard.enterprise",
          isVisible: true,
          icon: "keyboard_arrow_right",
          children: [
            {
              name: "planApproval.no_duplicate",
              isVisible: true,
              path: ConstantList.ROOT_PATH + "planAppro-no-duplicate",
            },
            {
              name: "planApproval.duplicate",
              isVisible: true,
              path: ConstantList.ROOT_PATH + "planAppro-duplicate",
            },
          ],
        },
      ],
    },

    {
      name: "Dashboard.progress_update",
      isVisible: true,
      icon: "feed",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_CONDUCT_INSPECTION
      ],
      children: [
        {
          name: "progress_update.adminitrative",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "update-progress-admin",
        },
        {
          name: "progress_update.enterprise",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "update-progress-enterprise",
        },
      ],
    },
    {
      name: "Dashboard.post-inspection-handle",
      isVisible: true,
      icon: "feed",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_INSPECTION_HANDLING
      ],
      children: [ 
        {
          name: "post-inspection-handle.adminitrative",
          isVisible: true,
          icon: "keyboard_arrow_right",
          roles: [
            ConstantList.listRoleName.ROLE_ADMIN,
            ConstantList.listRoleName.ROLE_SUPER_ADMIN,
            ConstantList.listRoleName.ROLE_INSPECTION_HANDLING,
          ],
          children: [
            {
              name: "post-inspection-handle.sub.summary",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspection/listConclusionAdmin/"
            },
            {
              name: "post-inspection-handle.sub.follow",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspectionAdmin/" +
                ConstantList.INSPECTIONS_TYPE_ADMINISTRATION +
                "/" +
                ConstantList.INSPECTION_MONITOR_STATUS_FOLLOW,
            },
            {
              name: "post-inspection-handle.sub.urge",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspectionAdmin/" +
                ConstantList.INSPECTIONS_TYPE_ADMINISTRATION +
                "/" +
                ConstantList.INSPECTION_MONITOR_STATUS_URGE,
            },
            {
              name: "post-inspection-handle.sub.check",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspectionAdmin/" +
                ConstantList.INSPECTIONS_TYPE_ADMINISTRATION +
                "/" +
                ConstantList.INSPECTION_MONITOR_STATUS_CHECK,
            },
            {
              name: "post-inspection-handle.sub.request",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspectionAdmin/" +
                ConstantList.INSPECTIONS_TYPE_ADMINISTRATION +
                "/" +
                ConstantList.INSPECTION_MONITOR_STATUS_REQUEST,
            },
          ],
        },
        {
          name: "post-inspection-handle.enterprise",
          isVisible: true,
          roles: [
            ConstantList.listRoleName.ROLE_ADMIN,
            ConstantList.listRoleName.ROLE_SUPER_ADMIN,
            ConstantList.listRoleName.ROLE_INSPECTION_HANDLING
          ],
          icon: "keyboard_arrow_right",

          children: [
            {
              name: "post-inspection-handle.sub.summary",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspection/listConclusionEnterprise/"
            },
            {
              name: "post-inspection-handle.sub.follow",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspectionEnterprise" +
                "/" +
                ConstantList.INSPECTION_MONITOR_STATUS_FOLLOW,
            },
            {
              name: "post-inspection-handle.sub.urge",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspectionEnterprise" +
                "/" +
                ConstantList.INSPECTION_MONITOR_STATUS_URGE,
            },
            {
              name: "post-inspection-handle.sub.check",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspectionEnterprise" +
                "/" +
                ConstantList.INSPECTION_MONITOR_STATUS_CHECK,
            },
            {
              name: "post-inspection-handle.sub.request",
              isVisible: true,
              path:
                ConstantList.ROOT_PATH +
                "handlingAfterInspectionEnterprise" +
                "/" +
                ConstantList.INSPECTION_MONITOR_STATUS_REQUEST,
            },
          ],
        },
      ],
    },
    {
      name: "Dashboard.followupresoluted",
      isVisible: true,
      icon: "feed",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_RESOLVE_COMPLAINTS
      ],
      children: [
        {
          name: "followupresolution.upresolution",
          isVisible: true,
          path:
            ConstantList.ROOT_PATH +
            "followupresoluted/" +
            ConstantList.FOLLOWUPRESOLUTIONTYPE_COMPLAIN,
        },
        {
          name: "followupresolution.complaints",
          isVisible: true,
          path:
            ConstantList.ROOT_PATH +
            "followupresoluted/" +
            ConstantList.FOLLOWUPRESOLUTIONTYPE_DENOUNCE,
        },
        {
          name: "followupresolution.motional",
          isVisible: true,
          path:
            ConstantList.ROOT_PATH +
            "followupresoluted/" +
            ConstantList.FOLLOWUPRESOLUTIONTYPE_REQUEST,
        },
      ],
    },
    {
      name: "Dashboard.synthetic",
      isVisible: true,
      icon: "feed",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_SUMMARIZING_RESULT,
      ],
      children: [
        {
          name: "synthetic.adminitrative",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "synthetic-admin",
        },
        {
          name: "synthetic.enterprise",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "synthetic-enterprise",
        },
      ],
    },
    {
      name: "Dashboard.insOrg",
      isVisible: true,
      icon: "feed",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_UNIT_MANAGEMENT
      ],
      children: [
        {
          name: "inspections.insOrganization",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "insOrganization",
        },
        {
          name: "inspections.inspectedOrg",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "inspectedOrg",
        },
        {
          name: "inspections.relatedObject",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "relatedObject",
        },
      ],
    },
    {
      name: "Dashboard.lookup_and_form",
      isVisible: true,
      icon: "feed",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN,
        ConstantList.listRoleName.ROLE_REPORT,
        ConstantList.listRoleName.ROLE_APPROVAL_MANAGEMENT,
        ConstantList.listRoleName.ROLE_INSPECTION_HANDLING,
        ConstantList.listRoleName.ROLE_CONDUCT_INSPECTION,
        ConstantList.listRoleName.ROLE_SUMMARIZING_RESULT,
        ConstantList.listRoleName.ROLE_RESOLVE_COMPLAINTS,
        ConstantList.listRoleName.ROLE_PLAN_MANAGEMENT,
        ConstantList.listRoleName.ROLE_UNIT_MANAGEMENT
      ],
      children: [
        {
          name: "lookup_and_form.administrative_plan",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "searchadminitrative",
        },
        {
          name: "lookup_and_form.plan_enterprise",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "searchplanenterprise",
        },
      ],
    },
    {
      name: "Dashboard.directory",
      isVisible: true,
      icon: "dashboard",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN
      ],
      children: [
        {
          name: "Dashboard.AdministrativeUnit",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "dashboard/insAdministrativeUnit",
        },
      ],
    },
    // {
    //   name: "report.title",
    //   icon: "report",
    //   roles: [
    //     ConstantList.listRoleName.ROLE_ADMIN,
    //     ConstantList.listRoleName.ROLE_SUPER_ADMIN,
    //     ConstantList.listRoleName.ROLE_REPORT
    //   ],
    //   children: [
    //     {
    //       name: "Dashboard.reportEnterprisePlan",
    //       isVisible: true,
    //       path: ConstantList.ROOT_PATH + "reportEnterprisePlan",
    //     },
    //     {
    //       name: "Dashboard.reportAdministrationPlan",
    //       isVisible: true,
    //       path: ConstantList.ROOT_PATH + "reportAdministrationPlan",
    //     },
    //     {
    //       name: "Dashboard.reportSummarizationForm",
    //       isVisible: true,
    //       path: ConstantList.ROOT_PATH + "reportForm",
    //     },
    //   ],
    // },
    {
      name: "Dashboard.manage",
      isVisible: true,
      icon: "engineering",
      roles: [
        ConstantList.listRoleName.ROLE_ADMIN,
        ConstantList.listRoleName.ROLE_SUPER_ADMIN
      ],
      children: [
        {
          name: "manage.user",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "user_manager/user",
        },
        {
          name: "manage.menu",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "list/menu",
        },
        {
          name: "manage.form",
          isVisible: true,
          path: ConstantList.ROOT_PATH + "list/reportform",
        },
      ],
    },
  ];