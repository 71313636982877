import { EgretLoadable } from "egret";
import ConstantList from "../../../../appConfig";
import { withTranslation } from 'react-i18next';
const ListConclusionAdmin = EgretLoadable({
  loader: () => import("./ListConclusionAdmin")
});

const ViewComponent = withTranslation()(ListConclusionAdmin);

const ListConclusionAdminRouters = [
  {
    path:  ConstantList.ROOT_PATH+"handlingAfterInspection/listConclusionAdmin",
    exact: true,
    component: ViewComponent
  }
];

export default ListConclusionAdminRouters;
