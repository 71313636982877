import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { useTranslation, withTranslation, Trans } from "react-i18next";

const PlanApprovalDuplicateAdmin = EgretLoadable({
  loader: () => import("./PlanApprovalDuplicateAdminTable"),
});
const ViewComponent = withTranslation()(PlanApprovalDuplicateAdmin);
const planApprovalDuplicateAdminRoutes = [
  {
    path: ConstantList.ROOT_PATH + "planAppro-duplicate-admin",
    exact: true,
    component: ViewComponent,
    isPublic: false,
  },
];

export default planApprovalDuplicateAdminRoutes;
