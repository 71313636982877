import { EgretLoadable } from "egret";
import ConstantList from "../../../../appConfig";
import { withTranslation } from 'react-i18next';
const ListConclusionEnterprise = EgretLoadable({
  loader: () => import("./ListConclusionEnterprise")
});

const ViewComponent = withTranslation()(ListConclusionEnterprise);

const ListConclusionEnterpriseRouters = [
  {
    path:  ConstantList.ROOT_PATH+"handlingAfterInspection/listConclusionEnterprise",
    exact: true,
    component: ViewComponent
  }
];

export default ListConclusionEnterpriseRouters;
