import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';
const UpdateProgressAdmin = EgretLoadable({
  loader: () => import("./UpdateProgressAdmin")
});
const ViewComponent = withTranslation()(UpdateProgressAdmin);

const updateProgressAdminRoutes = [
  {
    path: ConstantList.ROOT_PATH + "update-progress-admin",
    exact: true,
    component: ViewComponent
  },
];

export default updateProgressAdminRoutes;