import axios from "axios";
import ConstantList from "../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/users/getCurrentUser";
const API_PATH_ORGID = ConstantList.API_ENPOINT + "/api/userOrganization/get-user-organization";

export const getCurrentUser = ()=> {
  var url = API_PATH;
  return axios.get(url);
};

export const getOrgId = (id) => {
  const config = { params: { userId: id } };
  return axios.get(API_PATH_ORGID , config);
};

