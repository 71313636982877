import jwtAuthService from "../../services/jwtAuthService";
import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "history.js";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ConstantList from "../../appConfig";
import i18n  from "i18n";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

toast.configure();

export function loginWithEmailAndPassword({ email, password }) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    });

    jwtAuthService
      //.loginWithEmailAndPassword(email, password)
      //.loginWithToken()
      .loginWithUserNameAndPassword(email, password)
      .then((user) => {
        const user1 = JSON.parse(localStorage.getItem("auth_user"));
        dispatch(setUserData(user1));
        //Lưu lại thông tin liên quan đến token tại đây
        //Nhảy đến trang HomePage dự kiến
      })
      .then(() => {
        history.push({
          pathname: ConstantList.ROOT_PATH + "dashboard/analytics",
        });
        return dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch((error) => {
        if(navigator?.onLine) {
          toast.warning(i18n.t("notification.loginIncorrect"));
        }

        // alert("Tài khoản hoặc mật khẩu không đúng. Mời bạn đăng nhập lại"); //Cần xem cách đưa ra thông báo thông qua đa ngôn ngữ
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  };
}

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return (dispatch) => {
    FirebaseAuthService.signInWithEmailAndPassword(email, password)
      .then((user) => {
        if (user) {
          dispatch(
            setUserData({
              userId: "1",
              role: "ADMIN",
              displayName: "Watson Joyce",
              email: "watsonjoyce@gmail.com",
              photoURL: "./assets/images/face-7.jpg",
              age: 25,
              token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
              ...user,
            })
          );

          history.push({
            pathname: "/",
          });

          return dispatch({
            type: LOGIN_SUCCESS,
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed",
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  };
}
