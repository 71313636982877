import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const SyntheticEnterprise = EgretLoadable({
  loader: () => import("./SyntheticEnterprise")
});
const ViewComponent = withTranslation()(SyntheticEnterprise);

const syntheticEnterpriseRoutes = [
  {
    path: ConstantList.ROOT_PATH + "synthetic-enterprise",
    exact: true,
    component: ViewComponent
  },
];

export default syntheticEnterpriseRoutes;