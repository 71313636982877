import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


const ApprovedEnterprise = EgretLoadable({
    loader: () => import("./ApprovedEnterprise")
  });
const ViewComponent = withTranslation()(ApprovedEnterprise);
const ApprovedEnterpriseRoutes = [
  {
    path: ConstantList.ROOT_PATH + "approvedenterprise",
    exact: true,
    component: ViewComponent,
   
  }
];

export default ApprovedEnterpriseRoutes;