import React from "react";
import { Redirect } from "react-router-dom";
import homeRoutes from "./views/home/HomeRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import UserRoutes from "./views/User/UserRoutes";
import updateProgressAdminRoutes from "./views/UpdateProgressAdmin/UpdateProgressAdminRoutes";
import updateProgressEnterpriseRoutes from "./views/UpdateProgressEnterprise/UpdateProgressEnterpriseRoutes";
import roleRoutes from "./views/Role/RoleRoutes";
import syntheticAdminRoutes from './views/SyntheticAdmin/SyntheticAdminRoutes'
import syntheticEnterpriseRoutes from "./views/SyntheticEnterprise/SyntheticEnterpriseRoutes"
import ConstantList from "./appConfig";
import MenuRoutes from "./views/Menus/MenuRoutes";
import ReportRoutes from "./views/FormReport/ReportRoutes";
import AdministrativeUnitRoutes from "./views/AdministrativeUnit/AdministrativeUnitRoutes";
import pageLayoutRoutes from "./views/page-layouts/PageLayoutRoutees";
import PlanningRoutes from "./views/Planning/PlanningRouter";
import RelatedObjectRoutesRoutes from "./views/RelatedObject/RelatedObjectRoutes";
import insOrganizationRoutesRoutes from "./views/InsOrganization/InsOrganizationRoutes";
import inspectedOrgRoutes from "./views/InspectedOrg/InspectedOrgRoutes";
import InsAdministrativeUnitRoutes from "./views/InsAdministrativeUnit/InsAdministrativeUnitRoutes";
import planApprovalDuplicateRoutes from "./views/PlanApprovalDuplicate/PlanApprovalDuplicateRoutes";
import planApprovalNoDuplicateRoutes from "./views/PlanApprovalNoDuplicate/PlanApprovalNoDuplicateRoutes"
import planApprovalAdminRoutes from "./views/PlanApprovalAdmin/PlanApprovalAdminRoutes";
import planApprovalDuplicateAdminRoutes from "./views/PlanApprovalDuplicateAdmin/PlanApprovalDuplicateAdminRoutes";
import ListConclusionAdminRouters from "./views/HandlingAfterInspection/ListConclusion/ListConclusionAdmin/ListConclusionAdminRouters";
import HandlingAfterInspectionAdminRouters from "./views/HandlingAfterInspection/HandlingAfterInspectionAdmin/HandlingAfterInspectionAdminRouters";
import SearchAdminitrativeRoutes from "./views/SearchplanAdminitrative/SearchAdminitrativeRouter";
import SearchplanEnterpriseRoutes from "./views/SearchplanEnterprise/SearchplanEnterpriseRoutes";
import EnterpriseRoutes from "./views/Enterprise/EnterpriseRoutes";
import AdministrationRoutes from "./views/Administration/AdministrationRoutes";
import CentralRoutes from "./views/Central/CentralRoutes";
import ReportEnterprisePlanRoutes from "./views/ReportEnterprisePlan/ReportEnterprisePlanRoutes";
import ReportAdministrationPlanRoutes from "./views/ReportAdministrationPlan/ReportAdministrationPlanRoutes";
import ReportFormRoutes from "./views/ReportForm/ReportFormRoutes";
import ConclusionAfterEnterpriseInspectionRoutes from "./views/ConclusionAfterEnterpriseInspection/ConclusionAfterEnterpriseInspectionRoutes";
import ConclusionAfterAdminInspectionRoutes from "./views/ConclusionAfterAdminInspection/ConclusionAfterAdminInspectionRoutes";
import FollowUpResolutionRoutes from "./views/FollowUpResolution/FollowUpResolutionRoutes";
import ApprovedAdminitrativeRoutes from "./views/ApprovedAdminitrative/ApprovedAdminitrativeRoutes";
import ApprovedEnterpriseRoutes from "./views/ApprovedEnterprise/ApprovedEnterpriseRoutes";
import ListConclusionEnterpriseRouters from "./views/HandlingAfterInspection/ListConclusion/ListConclusionEnterprise/ListConclusionEnterpriseRouters";
import HandlingAfterInspectionEnterpriseRouters from "./views/HandlingAfterInspection/HandlingAfterInspectionEnterprise/HandlingAfterInspectionEnterpriseRouters"

const redirectRoute = [
  {
    path: ConstantList.ROOT_PATH,
    exact: true,
    component: () => <Redirect to={ConstantList.HOME_PAGE} />, //Luôn trỏ về HomePage được khai báo trong appConfig
  },
];

const errorRoute = [
  {
    component: () => <Redirect to={ConstantList.ROOT_PATH + "session/404"} />,
  },
];

const routes = [
  ...homeRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...updateProgressAdminRoutes,
  ...updateProgressEnterpriseRoutes,
  ...planApprovalDuplicateRoutes,
  ...planApprovalNoDuplicateRoutes,
  ...syntheticAdminRoutes,
  ...syntheticEnterpriseRoutes,
  ...UserRoutes,
  ...roleRoutes,
  ...MenuRoutes,
  ...ReportRoutes,
  ...AdministrativeUnitRoutes,
  ...PlanningRoutes,
  ...RelatedObjectRoutesRoutes,
  ...insOrganizationRoutesRoutes,
  ...inspectedOrgRoutes,
  ...pageLayoutRoutes,
  ...redirectRoute,
  ...planApprovalAdminRoutes,
  ...InsAdministrativeUnitRoutes,
  ...ListConclusionAdminRouters,
  ...SearchAdminitrativeRoutes,
  ...SearchplanEnterpriseRoutes,
  ...HandlingAfterInspectionAdminRouters,
  ...EnterpriseRoutes,
  ...AdministrationRoutes,
  ...CentralRoutes,
  ...ReportEnterprisePlanRoutes,
  ...ReportAdministrationPlanRoutes,
  ...ReportFormRoutes,
  ...FollowUpResolutionRoutes,
  ...ConclusionAfterEnterpriseInspectionRoutes,
  ...ConclusionAfterAdminInspectionRoutes,
  ...ApprovedAdminitrativeRoutes,
  ...ApprovedEnterpriseRoutes,
  ...ListConclusionEnterpriseRouters,
  ...HandlingAfterInspectionEnterpriseRouters,
  ...planApprovalDuplicateAdminRoutes,
  ...errorRoute,

];

export default routes;
