import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


const ApprovedAdminitrative = EgretLoadable({
    loader: () => import("./ApprovedAdminitrative")
  });
const ViewComponent = withTranslation()(ApprovedAdminitrative);
const ApprovedAdminitrativeRoutes = [
  {
    path: ConstantList.ROOT_PATH + "approvedadminitrative",
    exact: true,
    component: ViewComponent,
   
  }
];

export default ApprovedAdminitrativeRoutes;