import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


const PlanApprovalNoDuplicate = EgretLoadable({
    loader: () => import("./PlanApprovalNoDuplicateTable")
  });
const ViewComponent = withTranslation()(PlanApprovalNoDuplicate);
const planApprovalNoDuplicateRoutes = [
  {
    path: ConstantList.ROOT_PATH + "planAppro-no-duplicate",
    exact: true,
    component: ViewComponent,
    isPublic: false
  }
];

export default planApprovalNoDuplicateRoutes;