import ConstantList from "./appConfig";
export const navigations = [
  {
    name: "Dashboard.dashboard",
    icon: "home",
    path: ConstantList.ROOT_PATH + "dashboard/analytics",
    isVisible: true,
    roles: [
      "ROLE_ADMIN",
      "ROLE_SUPER_ADMIN",
      "ROLE_REPORT",
      "ROLE_APPROVAL_MANAGEMENT",
      "ROLE_INSPECTION_HANDLING",
      "ROLE_CONDUCT_INSPECTION",
      "ROLE_SUMMARIZING_RESULT",
      "ROLE_UNIT_MANAGEMENT",
      "ROLE_RESOLVE_COMPLAINTS",
    ],
  },
  {
    name: "Dashboard.inspectionPlan",
    isVisible: true,
    icon: "feed",
    path: "/#",
    roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PLAN_MANAGEMENT"],
    children: [
      {
        name: "inspectionPlan.planning_central",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "central",
        // icon: "keyboard_arrow_right",
      },
      {
        name: "inspectionPlan.planning_admin",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "administration",
        // icon: "keyboard_arrow_right",
      },
      {
        name: "inspectionPlan.planning_enterprise",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "enterprise",
        // icon: "keyboard_arrow_right",
      },
    ],
  },

  {
    name: "Dashboard.planApproval",
    isVisible: true,
    icon: "feed",
    path: "/#",
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_APPROVAL_MANAGEMENT"],
    children: [
      {
        name: "Kế hoạch hành chính",
        isVisible: true,
        icon: "keyboard_arrow_right",
        path: "/#",
        children: [
          {
            name: "planApproval.no_duplicate",
            isVisible: true,
            path: ConstantList.ROOT_PATH + "planApproval-admin",
            // icon: "keyboard_arrow_right",
          },
          {
            name: "planApproval.duplicate",
            isVisible: true,
            path: ConstantList.ROOT_PATH + "planAppro-duplicate-admin",
          },

          // {
          //   name: "planApproval.approvedadminitrative",
          //   isVisible: true,
          //   path: ConstantList.ROOT_PATH + "approvedadminitrative",
          //   // icon: "keyboard_arrow_right",
          // },
        ],
      },
      {
        name: "Kế hoạch doanh nghiệp",
        isVisible: true,
        path: "/#",
        icon: "keyboard_arrow_right",
        children: [
          {
            name: "planApproval.no_duplicate",
            isVisible: true,
            path: ConstantList.ROOT_PATH + "planAppro-no-duplicate",
            // icon: "keyboard_arrow_right",
          },
          {
            name: "planApproval.duplicate",
            isVisible: true,
            path: ConstantList.ROOT_PATH + "planAppro-duplicate",
            // icon: "keyboard_arrow_right",
          },
          // {
          //   name: "planApproval.approvedenterprise",
          //   isVisible: true,
          //   path: ConstantList.ROOT_PATH + "approvedenterprise",

          //   // icon: "keyboard_arrow_right",
          // },
        ],
      },
    ],
  },

  {
    name: "Dashboard.progress_update",
    isVisible: true,
    icon: "feed",
    path: "/#",
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_CONDUCT_INSPECTION"],
    children: [
      {
        name: "progress_update.adminitrative",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "update-progress-admin",

        // icon: "keyboard_arrow_right",
      },
      {
        name: "progress_update.enterprise",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "update-progress-enterprise",

        // icon: "keyboard_arrow_right",
      },
    ],
  },

  {
    name: "Dashboard.post-inspection-handle",
    isVisible: true,
    icon: "feed",
    path: "/#",
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_INSPECTION_HANDLING"],
    children: [
      {
        name: "Nhập kết luận",
        isVisible: true,
        icon: "keyboard_arrow_right",
        path: "/#",
        children: [
          {
            name: "post-inspection-handle.sub.adminitrative",
            isVisible: true,
            path: ConstantList.ROOT_PATH + "conclusion-after-admin-inspection",
          },
          {
            name: "post-inspection-handle.sub.enterprise",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH + "conclusion-after-enterprise-inspection",
          },
        ],
      },
      {
        name: "post-inspection-handle.adminitrative",
        isVisible: true,
        path: "/#",
        icon: "keyboard_arrow_right",
        roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
        children: [
          {
            name: "post-inspection-handle.sub.summary",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfHandlingAfterInspection-Admin/" +
              ConstantList.INSPECTIONS_TYPE_ADMINISTRATION,
          },
          {
            name: "post-inspection-handle.sub.follow",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfInspectionConclusions-Admin/" +
              ConstantList.INSPECTIONS_TYPE_ADMINISTRATION +
              "/" +
              ConstantList.INSPECTION_MONITOR_STATUS_FOLLOW,
          },
          {
            name: "post-inspection-handle.sub.urge",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfInspectionConclusions-Admin/" +
              ConstantList.INSPECTIONS_TYPE_ADMINISTRATION +
              "/" +
              ConstantList.INSPECTION_MONITOR_STATUS_URGE,
          },
          {
            name: "post-inspection-handle.sub.check",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfInspectionConclusions-Admin/" +
              ConstantList.INSPECTIONS_TYPE_ADMINISTRATION +
              "/" +
              ConstantList.INSPECTION_MONITOR_STATUS_CHECK,
          },
          {
            name: "post-inspection-handle.sub.request",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfInspectionConclusions-Admin/" +
              ConstantList.INSPECTIONS_TYPE_ADMINISTRATION +
              "/" +
              ConstantList.INSPECTION_MONITOR_STATUS_REQUEST,
          },
        ],
      },
      {
        name: "post-inspection-handle.enterprise",
        isVisible: true,
        path: "/#",
        roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
        icon: "keyboard_arrow_right",

        children: [
          {
            name: "post-inspection-handle.sub.summary",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfHandlingAfterInspection-Enterprise/",

            // +
            // ConstantList.INSPECTIONS_TYPE_ENTERPRISE,
          },
          {
            name: "post-inspection-handle.sub.follow",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfInspectionConclusions-Enterprise" +
              "/" +
              ConstantList.INSPECTION_MONITOR_STATUS_FOLLOW,
          },
          {
            name: "post-inspection-handle.sub.urge",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfInspectionConclusions-Enterprise" +
              "/" +
              ConstantList.INSPECTION_MONITOR_STATUS_URGE,
          },
          {
            name: "post-inspection-handle.sub.check",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfInspectionConclusions-Enterprise" +
              "/" +
              ConstantList.INSPECTION_MONITOR_STATUS_CHECK,
          },
          {
            name: "post-inspection-handle.sub.request",
            isVisible: true,
            path:
              ConstantList.ROOT_PATH +
              "resultsOfInspectionConclusions-Enterprise" +
              "/" +
              ConstantList.INSPECTION_MONITOR_STATUS_REQUEST,
          },
        ],
      },
    ],
  },

  {
    name: "Dashboard.followupresoluted",
    isVisible: true,
    icon: "feed",
    path: "/#",
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_RESOLVE_COMPLAINTS"],
    children: [
      {
        name: "followupresolution.upresolution",
        isVisible: true,
        path:
          ConstantList.ROOT_PATH +
          "followupresoluted/" +
          ConstantList.FOLLOWUPRESOLUTIONTYPE_COMPLAIN,

        // icon: "keyboard_arrow_right",
      },
      {
        name: "followupresolution.complaints",
        isVisible: true,
        path:
          ConstantList.ROOT_PATH +
          "followupresoluted/" +
          ConstantList.FOLLOWUPRESOLUTIONTYPE_DENOUNCE,

        // icon: "keyboard_arrow_right",
      },
      {
        name: "followupresolution.motional",
        isVisible: true,
        path:
          ConstantList.ROOT_PATH +
          "followupresoluted/" +
          ConstantList.FOLLOWUPRESOLUTIONTYPE_REQUEST,

        // icon: "keyboard_arrow_right",
      },
    ],
  },

  {
    name: "Dashboard.synthetic",
    isVisible: true,
    icon: "feed",
    path: "/#",
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_SUMMARIZING_RESULT"],
    children: [
      {
        name: "synthetic.adminitrative",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "synthetic-admin",

        // icon: "keyboard_arrow_right",
      },
      {
        name: "synthetic.enterprise",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "synthetic-enterprise",

        // icon: "keyboard_arrow_right",
      },
    ],
  },
  {
    name: "Dashboard.insOrg",
    isVisible: true,
    path: "/#",
    icon: "feed",
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_UNIT_MANAGEMENT"],
    children: [
      {
        name: "inspections.insOrganization",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "insOrganization",
      },
      {
        name: "inspections.inspectedOrg",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "inspectedOrg",
      },
      {
        name: "inspections.relatedObject",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "relatedObject",
      },
    ],
  },
  {
    name: "Dashboard.lookup_and_form",
    isVisible: true,
    icon: "feed",
    path: "/#",
    roles: [
      "ROLE_ADMIN",
      "ROLE_SUPER_ADMIN",
      "ROLE_REPORT",
      "ROLE_APPROVAL_MANAGEMENT",
      "ROLE_INSPECTION_HANDLING",
      "ROLE_CONDUCT_INSPECTION",
      "ROLE_SUMMARIZING_RESULT",
      "ROLE_UNIT_MANAGEMENT",
      "ROLE_RESOLVE_COMPLAINTS",
    ],
    children: [
      {
        name: "lookup_and_form.administrative_plan",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "searchadminitrative",

        // icon: "keyboard_arrow_right",
      },
      {
        name: "lookup_and_form.plan_enterprise",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "searchplanenterprise",

        // icon: "keyboard_arrow_right",
      },
    ],
  },
  {
    name: "Dashboard.directory",
    isVisible: true,
    icon: "dashboard",
    path: "/#",
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    children: [
      {
        name: "Dashboard.AdministrativeUnit",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "dashboard/insAdministrativeUnit",
      },
    ],
  },
  {
    name: "report.title",
    icon: "report",
    path: ConstantList.ROOT_PATH,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_REPORT"],
    children: [
      {
        name: "Báo Cáo Kế Hoạch Doanh Nghiệp",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "reportEnterprisePlan",

        // icon: "keyboard_arrow_right",
      },
      {
        name: "Báo Cáo Kế Hoạch Hành Chính",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "reportAdministrationPlan",

        // icon: "keyboard_arrow_right",
      },
      {
        name: "Biểu Mẫu Báo Cáo Tổng Hợp",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "reportForm",

        // icon: "keyboard_arrow_right",
      },
    ],
  },
  {
    name: "Dashboard.manage",
    isVisible: true,
    icon: "engineering",
    path: "/#",
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    children: [
      {
        name: "manage.user",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "user_manager/user",

        // icon: "keyboard_arrow_right",
      },
      {
        name: "manage.menu",
        isVisible: true,
        path: ConstantList.ROOT_PATH + "list/menu",

        // icon: "keyboard_arrow_right",
      },
    ],
  },
];

export const navigationsUser = [];
