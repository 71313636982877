import ConstantList from "../../appConfig";
import { EgretLoadable } from "egret";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


const InsOrganization = EgretLoadable({
    loader: () => import("./InsOrganizationTable")
  });
const ViewComponent = withTranslation()(InsOrganization);
const insOrganizationRoutesRoutes = [
  {
    path: ConstantList.ROOT_PATH + "insOrganization",
    exact: true,
    component: ViewComponent,
    isPublic: false
  }
];

export default insOrganizationRoutesRoutes;