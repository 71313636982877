import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const ConclusionAfterEnterpriseInspection = EgretLoadable({
  loader: () => import("./ConclusionAfterEnterpriseInspection")
});

const ViewComponent = withTranslation()(ConclusionAfterEnterpriseInspection);

const ConclusionAfterEnterpriseInspectionRoutes = [
  {
    path:  ConstantList.ROOT_PATH+"conclusion-after-enterprise-inspection",
    exact: true,
    component: ViewComponent
  }
];

export default ConclusionAfterEnterpriseInspectionRoutes;
